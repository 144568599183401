<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_offer" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{lang.add_new}}</span>
                </div>
                <div @click="hide" id="hidaddcus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_title }}</label>
                    <b-form-input class="inborder" v-model="campaign.campaign_title" />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_start }}</label>
                    <b-form-input type="date" class="inborder" v-model="campaign.campaign_start" />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_start_time }}</label>
                    <b-form-input type="time" class="inborder" v-model="campaign.campaign_start_time" />
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data: () => ({
        offer: {
            offer_name: '',
        }
    }),
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        clientSource: function(){
            let t = [];
            for (let i = 0; i < this.sources.length; i++) {
                t.push({
                    text: this.sources[i].name_ar,
                    value: this.sources[i].value,
                });
            }
            return t;
        },
        getProds: function(){
            let t = [];
            for (let i = 0; i < this.optionsS.length; i++) {
                t.push({
                    text: this.optionsS[i].name_ar,
                    value: this.optionsS[i].value,
                });
            }
            return t;
        }
    }, 
    created(){
        // this.getStaticid('convertProducts');
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        resetAllValues(){
            this.offer = {
                offer_name: '',
            }
        },
        getStaticid(id) {
            const post = new FormData();
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
</style>